import React, { useState } from "react";
import { HeaderOfItem } from "./index";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import Walking from "../assets/PNG/walk2.png";
import Bike from "../assets/PNG/bike.png";
import Motor from "../assets/PNG/motor.png";

const TransportOptions = ({ selectedItem, setSelecteditem }) => {
  // Style
  const styles = {
    fields: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      backgroundColor: "#fff",
      paddingRight: "16px",
      backgroundColor: "white",
      boxShadow: "0 1px 4px #00000040",
      borderRadius: "2px",
      padding: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "4px",
      marginTop: "32px",
    },
    button: {
      border: "1px solid lightblue",
      fontSize: "12px",
      paddingTop: "12px",
      paddingBottom: "12px",
      textTransform: "none",
      width: "200px",
    },

    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    img: {
      width: "50px",
    },
    itemContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      height: "80px",
      color: "black",
      flex: 1,
      "&:hover": {
        backgroundColor: "lightblue",
      },
    },
    imgContainer: {
      height: "60px",
      width: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  const { transportOptions: transport } = useSelector((state) => state.data);
  const [num, setNum] = useState(null);
  const [selected, setSelected] = useState(null);

  return (
    <Box sx={styles.fields}>
      <HeaderOfItem
        selectedItem={selectedItem}
        setSelecteditem={setSelecteditem}
        title={"Transport Options"}
        icon={null}
        num={4}
      />

      <Box>
        {selectedItem === 4 ? (
          <>
            <Box sx={styles.container}>
              <Button
                sx={{
                  ...styles.itemContainer,
                  backgroundColor: num === 1 ? "lightblue" : null,
                }}
                onClick={() => {
                  setNum(1);
                  setSelected({
                    title: "driving",
                    img: Motor,
                  });
                }}
              >
                <Box sx={styles.imgContainer}>
                  <img src={Motor} style={styles.img} />
                </Box>
                A$ {transport?.driving ? "13 - 18 min" : null}
              </Button>
              <Button
                sx={{
                  ...styles.itemContainer,
                  backgroundColor: num === 2 ? "lightblue" : null,
                }}
                onClick={() => {
                  setNum(2);
                  setSelected({
                    title: "bicycling",
                    img: Bike,
                  });
                }}
              >
                <Box sx={styles.imgContainer}>
                  <img src={Bike} style={styles.img} />
                </Box>
                A$ {transport?.bicycling ? "18 - 24 min" : null}
              </Button>
              <Button
                sx={{
                  ...styles.itemContainer,
                  backgroundColor: num === 3 ? "lightblue" : null,
                }}
                onClick={() => {
                  setNum(3);
                  setSelected({
                    title: "walking",
                    img: Walking,
                  });
                }}
              >
                <Box sx={styles.imgContainer}>
                  <img src={Walking} style={styles.img} />
                </Box>
                A$ {transport?.walking ? "24 - 32 min" : null}
              </Button>
            </Box>

            <Box>
              <Box sx={styles.buttonContainer}>
                <Button
                  sx={styles.button}
                  onClick={() => {
                    setSelecteditem(5);
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            style={{
              width: window.innerWidth / 3,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Box sx={styles.imgContainer}>
              <img src={selected?.img} style={styles.img} />
            </Box>
            {selected?.title}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TransportOptions;
