import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useDispatch } from "react-redux";
import { setTransportOptions } from "../../redux/data/dataSlice";

const ParcelItem = ({ i, item, selectedParcel, setSelectedParcel }) => {
  const dispatch = useDispatch();

  // Style
  const styles = {
    parcelItem: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      padding: "12px 24px 12px 24px",
      color: "grey",
      width: "100%",
      "&:hover": {
        backgroundColor: "lightblue",
      },
    },
    parcelImg: {
      flex: 1,
    },
    parcelImgCon: {
      width: "60px",
      padding: "12px",
      backgroundColor: "lightgrey",
    },
    parcelType: {
      textAlign: "start",
      flex: 3,
    },
    parcelDes: {
      flex: 2,
      fontSize: "12px",
    },
  };

  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const storage = getStorage();
    const imageRef = ref(storage, item.parcel_img_url);

    getDownloadURL(imageRef)
      .then((url) => {
        setImageUrl(url);
      })
      .catch((error) => {
        console.error("Error fetching image URL: ", error);
      });
  }, []);

  return (
    <Button
      key={i}
      sx={{
        ...styles.parcelItem,
        backgroundColor:
          item.parcel_type === selectedParcel?.parcel_type ? "lightblue" : null,
      }}
      onClick={() => {
        setSelectedParcel({ ...item, imageUrl: imageUrl });
        dispatch(setTransportOptions(item.vehicle_type));
      }}
    >
      <Box sx={styles.parcelImg}>
        <Box sx={styles.parcelImgCon}>{imageUrl && <img src={imageUrl} />}</Box>
      </Box>
      <Box sx={styles.parcelType}>{item.parcel_type}</Box>
      <Box sx={styles.parcelDes}>
        <Box>
          {item.parcel_min_weight} - {item.parcel_max_weight} kg max
        </Box>
        <Box>{item.parcel_description}</Box>
      </Box>
    </Button>
  );
};

export default ParcelItem;
