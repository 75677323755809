import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  originAutocomplete: null,
  destinationAutocomplete: null,
  parcels: null,
  transportOptions: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    changeOriginAC: (state, { payload }) => {
      state.originAutocomplete = payload;
    },

    changeDestinationAC: (state, { payload }) => {
      state.destinationAutocomplete = payload;
    },

    setParcel: (state, { payload }) => {
      state.parcels = payload;
    },

    setTransportOptions: (state, { payload }) => {
      state.transportOptions = payload;
    },
  },
});

export const {
  changeOriginAC,
  changeDestinationAC,
  setParcel,
  setTransportOptions,
} = dataSlice.actions;
export default dataSlice.reducer;
