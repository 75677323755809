import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { Box } from "@mui/material";
import {
  Map,
  Origin,
  Destination,
  ParcelsType,
  TransportOptions,
} from "./components/index";
import { data } from "./data";
import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, onValue } from "firebase/database";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { setParcel } from "./redux/data/dataSlice";

function App() {
  const dispatch = useDispatch();

  // Style
  const styles = {
    container: {
      display: "flex",
      height: "100vh",
    },

    fields: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      backgroundColor: "#efefef",
      padding: "4px",
      paddingRight: "16px",
    },

    map: {
      flex: 2,
    },
  };

  const [selectedItem, setSelecteditem] = useState(1);

  const firebaseConfig = {
    apiKey: "AIzaSyASGf3xaQKOEsMZaYET96y4yh0GI9oI4pk",
    authDomain: "uber4things.firebaseapp.com",
    databaseURL: "https://uber4things.firebaseio.com",
    projectId: "uber4things",
    storageBucket: "uber4things.appspot.com",
    messagingSenderId: "269078947820",
    appId: "1:269078947820:web:e78318cd4e8fb44354c2c9",
    measurementId: "G-LW7HN2YY44",
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);

  const getCollectionData = async () => {
    const querySnapshot = await getDocs(collection(db, "bearerParcels"));

    let parcels = [];

    querySnapshot.forEach((doc) => {
      parcels.push(doc.data());
    });

    console.log(parcels);

    dispatch(setParcel(parcels));
  };

  useEffect(() => {
    getCollectionData();
  }, []);

  return (
    <Box sx={styles.container}>
      {/* Fields */}
      <Box sx={styles.fields}>
        <Origin selectedItem={selectedItem} setSelecteditem={setSelecteditem} />
        <Destination
          selectedItem={selectedItem}
          setSelecteditem={setSelecteditem}
        />
        <ParcelsType
          selectedItem={selectedItem}
          setSelecteditem={setSelecteditem}
        />
        <TransportOptions
          selectedItem={selectedItem}
          setSelecteditem={setSelecteditem}
        />
      </Box>

      {/* Map */}
      <Box sx={styles.map}>
        <Map />
      </Box>
    </Box>
  );
}

export default App;
