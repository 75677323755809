import React, { useEffect, useRef, useState } from "react";
import DestinationLogo from "../assets/PNG/destination_pin.png";
import { HeaderOfItem } from "./index";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { changeDestinationAC } from "../redux/data/dataSlice";

const Destination = ({ selectedItem, setSelecteditem }) => {
  const dispatch = useDispatch();

  // Style
  const styles = {
    fields: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      backgroundColor: "#fff",
      paddingRight: "16px",
      backgroundColor: "white",
      boxShadow: "0 1px 4px #00000040",
      borderRadius: "2px",
      padding: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      marginTop: "32px",
    },
    button: {
      border: "1px solid lightblue",
      fontSize: "12px",
      flex: 1,
      paddingTop: "12px",
      paddingBottom: "12px",
      textTransform: "none",
    },
    addressText: {
      fontSize: "20px",
      color: "grey",
      wordWrap: "break-word",
    },
    delivery: {
      marginTop: "8px",
      marginBottom: "4px",
    },
    radio: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  };

  const [destinationVals, setDestinationVals] = useState({
    address: "",
    details: "",
    phoneNumber: "",
    recipientName: "",
  });

  const destinationChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setDestinationVals({ ...destinationVals, [name]: value });
  };

  const confirmationHandler = () => {
    setSelecteditem(3);
  };

  const [autocomplete, setAutocomplete] = useState(null);
  const autocompleteRef = useRef(null);

  return (
    <Box sx={styles.fields}>
      <HeaderOfItem
        selectedItem={selectedItem}
        setSelecteditem={setSelecteditem}
        title={"Destination"}
        icon={DestinationLogo}
        num={2}
      />

      <Box>
        {selectedItem === 2 ? (
          <>
            <Grid container spacing={1}>
              <Grid item={true} xs={12}>
                <TextField
                  name="address"
                  id="autocomplete"
                  label="Address"
                  variant="filled"
                  fullWidth
                  inputRef={autocompleteRef}
                  onFocus={() => {
                    if (window.google && window.google.maps) {
                      const options = {
                        types: ["address"],
                        fields: ["formatted_address", "geometry"],
                      };
                      const autocompleteInstance =
                        new window.google.maps.places.Autocomplete(
                          autocompleteRef.current,
                          options
                        );

                      setAutocomplete(autocompleteInstance);
                    }
                  }}
                  onBlur={() => {
                    setDestinationVals({
                      ...destinationVals,
                      address: autocomplete.gm_accessors_.place.Os.Fg[0].Hg[0],
                    });

                    dispatch(changeDestinationAC(autocomplete));
                  }}
                  value={destinationVals.address}
                  onChange={destinationChangeHandler}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  variant="filled"
                  fullWidth
                  label="More Details / Message for the Rider"
                  sx={styles.textField}
                  type="text"
                  value={destinationVals.details}
                  name="details"
                  onChange={destinationChangeHandler}
                />
              </Grid>

              <Grid item={true} xs={6}>
                <TextField
                  variant="filled"
                  fullWidth
                  label="Phone Number"
                  sx={styles.textField}
                  type="number"
                  value={destinationVals.phoneNumber}
                  name="phoneNumber"
                  onChange={destinationChangeHandler}
                />
              </Grid>

              <Grid item={true} xs={6}>
                <TextField
                  variant="filled"
                  fullWidth
                  label="Recipient's Name"
                  sx={styles.textField}
                  type="text"
                  value={destinationVals.recipientName}
                  name="recipientName"
                  onChange={destinationChangeHandler}
                />
              </Grid>
            </Grid>
            <Box sx={styles.delivery}>Delivery Approval by:</Box>
            <Box>
              <RadioGroup sx={styles.radio}>
                <FormControlLabel
                  control={<Radio />}
                  label="Confirmation Code"
                  value="confirmationCode"
                  sx={{ flex: 1 }}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Not Needed"
                  value="notNeeded"
                  sx={{ flex: 1 }}
                />
              </RadioGroup>
            </Box>
            <Box>
              <Box sx={styles.buttonContainer}>
                <Button sx={styles.button}>Choose from Favourites</Button>
                <Button
                  sx={styles.button}
                  onClick={() => confirmationHandler()}
                >
                  Confirm Destination
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <Box style={{ width: window.innerWidth / 3 }} sx={styles.addressText}>
            {destinationVals.address}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Destination;
