import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeaderOfItem, ParcelItem } from "./index";
import { useSelector } from "react-redux";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const ParcelsType = ({ selectedItem, setSelecteditem }) => {
  // Style
  const styles = {
    fields: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      backgroundColor: "#fff",
      paddingRight: "16px",
      backgroundColor: "white",
      boxShadow: "0 1px 4px #00000040",
      borderRadius: "2px",
      padding: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "4px",
      marginTop: "32px",
    },
    button: {
      border: "1px solid lightblue",
      fontSize: "12px",
      paddingTop: "12px",
      paddingBottom: "12px",
      textTransform: "none",
      width: "200px",
    },

    imgTypeContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      fontSize: "20px",
    },
  };

  const { parcels } = useSelector((state) => state.data);
  const [selectedParcel, setSelectedParcel] = useState(null);

  return (
    <Box sx={styles.fields}>
      <HeaderOfItem
        selectedItem={selectedItem}
        setSelecteditem={setSelecteditem}
        title={"Parcel's Type"}
        icon={null}
        num={3}
      />

      <Box>
        {selectedItem === 3 ? (
          <>
            {parcels &&
              parcels.map((item, i) => {
                return (
                  <ParcelItem
                    i={i}
                    item={item}
                    selectedParcel={selectedParcel}
                    setSelectedParcel={setSelectedParcel}
                  />
                );
              })}
            <Box>
              <Box sx={styles.buttonContainer}>
                <Button
                  sx={styles.button}
                  onClick={() => {
                    setSelecteditem(4);
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            style={{ width: window.innerWidth / 3 }}
            sx={styles.imgTypeContainer}
          >
            {selectedParcel?.imageUrl && <img src={selectedParcel.imageUrl} />}
            {selectedParcel?.parcel_type}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ParcelsType;
