import React, { useState, useRef, useEffect } from "react";
import OriginMarker from "../assets/PNG/origin_pin.png";
import DestinationMarker from "../assets/PNG/destination_pin.png";
import {
  GoogleMap,
  LoadScript,
  MarkerF,
  Autocomplete,
  Polyline,
} from "@react-google-maps/api";
import { Box } from "@mui/material";
import { data } from "../data";
import { useSelector } from "react-redux";

const Map = () => {
  // Style
  const styles = {
    container: {
      display: "flex",
      height: "100vh",
    },

    itemContainer: {
      backgroundColor: "white",
      flex: 1,
      boxShadow: "0 1px 4px #00000040",
      borderRadius: "2px",
      padding: "20px",
    },

    fields: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      backgroundColor: "#efefef",
      padding: "4px",
      paddingRight: "16px",
    },

    map: {
      flex: 2,
      position: "relative",
      height: "100vh",
    },
  };

  const { originAutocomplete, destinationAutocomplete } = useSelector(
    (state) => state.data
  );

  const [map, setMap] = useState(null);

  const [oriPosition, setOriPosition] = useState(null);
  const [desPosition, setDesPosition] = useState(null);
  const [lastPosition, setLastPosition] = useState({
    lat: 41.3851,
    lng: 2.1734,
  });

  useEffect(() => {
    if (originAutocomplete) {
      originAutocomplete.addListener("place_changed", () => {
        const place = originAutocomplete.getPlace();
        if (place.geometry) {
          setOriPosition({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
          setLastPosition({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
          map.panTo(place.geometry.location);
        }
      });
    }
  }, [originAutocomplete, map]);

  useEffect(() => {
    if (destinationAutocomplete) {
      destinationAutocomplete.addListener("place_changed", () => {
        const place = destinationAutocomplete.getPlace();
        if (place.geometry) {
          setDesPosition({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
          setLastPosition({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
          map.panTo(place.geometry.location);
        }
      });
    }
  }, [destinationAutocomplete, map]);

  useEffect(() => {
    if (oriPosition && desPosition) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(oriPosition);
      bounds.extend(desPosition);
      map.fitBounds(bounds);
    }
  }, [oriPosition, desPosition]);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyASGf3xaQKOEsMZaYET96y4yh0GI9oI4pk"
      libraries={["places"]}
    >
      <Box sx={styles.map}>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "100%",
          }}
          zoom={15}
          center={lastPosition}
          onLoad={(map) => setMap(map)}
        >
          {window.google && originAutocomplete && (
            <MarkerF
              position={oriPosition}
              icon={{
                url: OriginMarker,
                scaledSize: new window.google.maps.Size(26, 40),
              }}
            />
          )}

          {window.google && destinationAutocomplete && (
            <MarkerF
              position={desPosition}
              icon={{
                url: DestinationMarker,
                scaledSize: new window.google.maps.Size(22, 40),
              }}
            />
          )}

          {oriPosition && desPosition && (
            <Polyline
              path={[oriPosition, desPosition]}
              options={{
                strokeColor: "orange",
                strokeOpacity: 0.5,
                strokeWeight: 1,
                geodesic: true,
                icons: [
                  {
                    icon: {
                      path: "M 0,-1 0,1",
                      strokeOpacity: 1,
                      scale: 3,
                    },
                    offset: "0",
                    repeat: "20px",
                  },
                ],
              }}
            />
          )}
        </GoogleMap>
      </Box>
    </LoadScript>
  );
};

export default Map;
