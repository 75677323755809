import { Box, Button } from "@mui/material";
import React from "react";

const styles = {
  headerItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "6px",
    marginBottom: "8px",
  },
  headerImg: {
    width: "16px",
  },
  headerButton: {
    textTransform: "capitalize",
    color: "grey",
  },
};

const HeaderOfItem = ({ title, selectedItem, setSelecteditem, icon, num }) => {
  const clickHandler = () => {
    setSelecteditem(num);
  };

  return (
    <Box sx={styles.headerItem}>
      <Box sx={styles.headerItem}>
        {icon && <img style={styles.headerImg} src={icon} />}

        <Box>{title}</Box>
      </Box>
      <Button sx={styles.headerButton} onClick={clickHandler}>
        {selectedItem === num ? "clear" : "edit"}
      </Button>
    </Box>
  );
};

export default HeaderOfItem;
