import OriginLogo from "./assets/PNG/origin_pin.png";
import DestinationLogo from "./assets/PNG/destination_pin.png";

export const data = [
  {
    id: "ori",
    title: "Origin",
    icon: OriginLogo,
    inputs: [
      {
        id: "origin",
        label: "Address",
        name: "address",
        xs: 12,
        type: "",
      },
      {
        label: "More Details / Message for the Rider",
        name: "details",
        xs: 12,
        type: "text",
      },
      {
        label: "Phone Number",
        name: "phoneNumber",
        xs: 6,
        type: "number",
      },
      {
        label: "Sender's Name",
        name: "senderName",
        xs: 6,
        type: "text",
      },
    ],
  },
  {
    id: "des",
    title: "Destination",
    icon: DestinationLogo,
    inputs: [
      {
        label: "Address",
        name: "address",
        xs: 12,
        type: "",
      },
      {
        label: "More Details / Message for the Rider",
        name: "details",
        xs: 12,
        type: "text",
      },
      {
        label: "Phone Number",
        name: "phoneNumber",
        xs: 6,
        type: "number",
      },
      {
        label: "Recipient's Name",
        name: "recipientName",
        xs: 6,
        type: "text",
      },
    ],
  },
  {
    id: "par",
    title: "Parcel's Type",
    icon: null,
    inputs: [],
  },
  {
    id: "tra",
    title: "Transport Options",
    icon: null,
    inputs: [],
  },
];
