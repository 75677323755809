import React, { useEffect, useRef, useState } from "react";
import OriginLogo from "../assets/PNG/origin_pin.png";
import { HeaderOfItem } from "./index";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { changeOriginAC } from "../redux/data/dataSlice";

const Origin = ({ selectedItem, setSelecteditem }) => {
  const dispatch = useDispatch();

  // Style
  const styles = {
    fields: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      backgroundColor: "#fff",
      paddingRight: "16px",
      backgroundColor: "white",
      boxShadow: "0 1px 4px #00000040",
      borderRadius: "2px",
      padding: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      marginTop: "32px",
    },
    button: {
      border: "1px solid lightblue",
      fontSize: "12px",
      flex: 1,
      paddingTop: "12px",
      paddingBottom: "12px",
      textTransform: "none",
    },
    addressText: {
      fontSize: "20px",
      color: "grey",
      wordWrap: "break-word",
    },
  };

  const [originVals, setOriginVals] = useState({
    address: "",
    details: "",
    phoneNumber: "",
    senderName: "",
  });

  const originChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setOriginVals({ ...originVals, [name]: value });
  };

  const confirmationHandler = () => {
    setSelecteditem(2);
  };

  const [autocomplete, setAutocomplete] = useState(null);
  const autocompleteRef = useRef(null);

  return (
    <Box sx={styles.fields}>
      <HeaderOfItem
        selectedItem={selectedItem}
        setSelecteditem={setSelecteditem}
        title={"Origin"}
        icon={OriginLogo}
        num={1}
      />

      <Box>
        {selectedItem === 1 ? (
          <>
            <Grid container spacing={1}>
              <Grid item={true} xs={12}>
                <TextField
                  name="address"
                  id="autocomplete"
                  label="Address"
                  variant="filled"
                  fullWidth
                  inputRef={autocompleteRef}
                  onFocus={() => {
                    if (window.google && window.google.maps) {
                      const options = {
                        types: ["address"],
                        fields: ["formatted_address", "geometry"],
                      };
                      const autocompleteInstance =
                        new window.google.maps.places.Autocomplete(
                          autocompleteRef.current,
                          options
                        );

                      setAutocomplete(autocompleteInstance);
                    }
                  }}
                  onBlur={() => {
                    setOriginVals({
                      ...originVals,
                      address: autocomplete.gm_accessors_.place.Os.Fg[0].Hg[0],
                    });

                    dispatch(changeOriginAC(autocomplete));
                  }}
                  value={originVals.address}
                  onChange={originChangeHandler}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  variant="filled"
                  fullWidth
                  label="More Details / Message for the Rider"
                  sx={styles.textField}
                  type="text"
                  value={originVals.details}
                  name="details"
                  onChange={originChangeHandler}
                />
              </Grid>

              <Grid item={true} xs={6}>
                <TextField
                  variant="filled"
                  fullWidth
                  label="Phone Number"
                  sx={styles.textField}
                  type="number"
                  value={originVals.phoneNumber}
                  name="phoneNumber"
                  onChange={originChangeHandler}
                />
              </Grid>

              <Grid item={true} xs={6}>
                <TextField
                  variant="filled"
                  fullWidth
                  label="Sender's Name"
                  sx={styles.textField}
                  type="text"
                  value={originVals.senderName}
                  name="senderName"
                  onChange={originChangeHandler}
                />
              </Grid>
            </Grid>
            <Box>
              <Box sx={styles.buttonContainer}>
                <Button sx={styles.button}>Choose from Favourites</Button>
                <Button
                  sx={styles.button}
                  onClick={() => confirmationHandler()}
                >
                  Confirm Origin
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <Box style={{ width: window.innerWidth / 3 }} sx={styles.addressText}>
            {originVals.address}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Origin;
